import React from 'react';
import './App.css';
import { useEffect } from 'react';
import routes from './routes/routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


const routing = createBrowserRouter(routes);

function App() {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript('theme/assets/lib/mobile-nav/mobile-nav.js');
        await loadScript('theme/assets/js/main.js');
      } catch (error) {
        console.error('Script yüklenirken hata oluştu:', error);
      }
    };

    loadScripts();
  }, []);
  return (

    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={routing} />
    </React.Suspense>
   
 
  );
}

export default App;
