import RootLayout from "../layouts/RootLayout";
import Home from "../pages/Home";
import Projects from "../pages/Projects";



const routes = [

    {
        path: '/',
        element:<RootLayout/>,
        children:[
            {
                index:true,
                element:<Home/>
            },
            {
                path:"projects",
                element:<Projects/>
            }
        ]
    }

]



export default routes