import React from 'react';

export default function Projects() {
  return (
    <section id="portfolio" className="mt-5">
      <div className="container">
        
        
        <div className="row feature-item">
          <div className="col-lg-6 wow fadeInUp">
            <img
              src="theme/assets/img/portfolio/softbor.png"
              className="img-fluid"
              alt="SoftBor"
            />
          </div>
          <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
            <h4 style={{color: "#1bb1dc", fontWeight: "bold"}}>SoftBor</h4>
            <p>
              Softsense'nin bor katkılı izolasyon malzemesi, 1000°C sıcaklığa kadar dayanarak yangın riskini minimuma indirir. 
              Enerji verimliliğini artıran bu malzeme, sürdürülebilir projelere katkı sağlamak amacıyla geliştirilmiştir.
            </p>
            <p>
              Yüksek performanslı ses yalıtımı sayesinde hem konut hem de ticari binalarda kullanılabilir. 
              İnşaat, otomotiv ve havacılık sektörlerinde inovatif çözümler sunan SoftBor, enerji maliyetlerini %20’ye varan oranlarda azaltır.
            </p>
            <p>
              Hafif yapısı ve uzun ömrüyle, nakliye ve bakım maliyetlerini düşürerek sürdürülebilir bir tercih sunar. 
              Güvenli yapısıyla mimari projelerde yeni standartlar oluşturur.
            </p>
          </div>
        </div>

     
        <div className="row feature-item mt-5 pt-5">
          <div className="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img
              src="theme/assets/img/portfolio/ar.png"
              className="img-fluid"
              alt="Artırılmış Gerçeklik"
            />
          </div>
          <div className="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h4 style={{ color: '#1bb1dc', fontWeight: 'bold' }}>Artırılmış Gerçeklik</h4>
            <p>
              Softsense'nin artırılmış gerçeklik çözümleri, elektrik panoları ve kritik sistemlerin uzaktan yönetiminde devrim yaratıyor. 
              MiCOM P111 gibi koruma röleleri, anlık veri izleme ve müdahale imkanı sunarak operasyonel hataları minimuma indirir.
            </p>
            <p>
              Operatörler, sahada bulunmadan ekipmanların durumunu analiz edip yönetebilir. 
              Bu, hem bakım süreçlerini hızlandırır hem de maliyetleri düşürür. 
              Güvenliği artırarak, iş kazalarının önüne geçmeyi amaçlar.
            </p>
            <p>
              Enerji ve otomotiv sektörlerinde yaygın olarak kullanılan artırılmış gerçeklik teknolojisi, 
              karmaşık sistemlerin yönetiminde şeffaflık sağlar ve verimliliği en üst seviyeye taşır.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
}
