import React from 'react'

export default function Footer() {
  return (
   <>
   <footer id="footer" className="section-bg">
  <div className="footer-top">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="footer-info">
            <h3>Softsense</h3>
            <p>
              Softsense olarak, işinizi dijital dünyaya entegre etmek ve en iyi çözümleri sunmak için buradayız. Teknolojiyi sizin için kolaylaştırıyor, verimliliğinizi artırıyoruz. Profesyonel ekibimizle geleceğe yönelik yenilikçi yazılım çözümleri üretiyoruz.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="footer-links">
            <h4>Hızlı Bağlantılar</h4>
            <ul>
              <li><a href="/">Ana Sayfa</a></li>
              <li><a href="#services">Hizmetlerimiz</a></li>
              <li><a href="#portfolio">Portföyümüz</a></li>
              <li><a href="#footer">İletişim</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="footer-links">
            <h4>Bizimle İletişime Geçin</h4>
            <p>
              <strong>Adres:</strong> Batman Teknokent Kat: 1 No: 101<br />
              <strong>Telefon:</strong> +0 532 509 2888<br />
              <strong>E-posta:</strong> bilgi.softsense@gmail.com<br />
            </p>
          </div>
          <div className="social-links">
            <a href="#" className="twitter"><i className="fa fa-twitter" /></a>
            <a href="#" className="facebook"><i className="fa fa-facebook" /></a>
            <a href="#" className="instagram"><i className="fa fa-instagram" /></a>
            <a href="#" className="linkedin"><i className="fa fa-linkedin" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
    <div className="copyright">
      © Copyright <strong>Softsense</strong>. Tüm Hakları Saklıdır
    </div>
  </div>
  </div>
 
</footer>
<a href="#" className="back-to-top">
  <i className="fa fa-chevron-up" />
</a>

    </>
  )
}
