import React from 'react';
import { useLocation } from 'react-router-dom'; 

export default function Header() {
  const location = useLocation(); 


  const renderNavbar = () => {
    if (location.pathname === '/projects') {
      return (
        <ul>
          <li >
            <a href="/">Ana Sayfa</a>
          </li>
          <li className='active'>
            <a href="#portfolio">Portföyümüz</a>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li className="active">
            <a href="/">Ana Sayfa</a>
          </li>
          <li>
            <a href="#about">Hakkımızda</a>
          </li>
          <li>
            <a href="#services">Hizmetlerimiz</a>
          </li>
          <li>
            <a href="#portfolio">Portföyümüz</a>
          </li>
          <li>
            <a href="#contact">İletişim</a>
          </li>
        </ul>
      );
    }
  };

  return (
    <header id="header">
      <div className="container">
        <div className="logo float-left">
          <a href="#header" className="scrollto">
            <img src="logo.png" alt="" className="img-fluid" />
          </a>
        </div>
        <nav className="main-nav float-right d-none d-lg-block">
          {renderNavbar()} 
        </nav>
      </div>
    </header>
  );
}
