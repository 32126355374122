import React from 'react'
import { FaMobileAlt, FaPaintBrush, FaCode, FaChalkboardTeacher, FaComments, FaTools } from 'react-icons/fa';

export default function Home() {
 
  return (
    <>
      <section id="intro" className="clearfix">
        <div className="container d-flex h-100">
          <div className="row justify-content-center align-self-center">
            <div className="col-md-6 intro-info order-md-first order-last">
              <h2>
                2021'den bu yana,
                <br />
                <span>Batman Teknokent'te Yenilikçi Çözümler!</span>
              </h2>
              <p>
              Firmamız, akademisyenlerin kurduğu bir konsorsiyum olup AR-GE, danışmanlık ve eğitim alanlarında hizmet vermektedir. Batman Teknokent’in ilk firması olarak sektörde başarıyla faaliyet göstermekteyiz.
              </p>
              <div>
                <a href="#about" className="btn-get-started scrollto">
                  Bizi Keşfedin
                </a>
              </div>
            </div>
            <div className="col-md-6 intro-img order-md-last order-first">
              <img src="theme/assets/img/intro-img.svg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="theme/assets/img/about-us.png" alt="SoftSense" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="about-content">
                  <h2>Hakkımızda</h2>
                  <h3>
                    Softsense, yenilikçi çözümler ve yüksek kaliteli yazılım hizmetleri sunan bir firmadır.
                  </h3>
                  <p>
                    2021 yılında Batman Teknokent'te kurulan firmamız, bilişim sektöründe AR-GE, yazılım geliştirme, danışmanlık ve eğitim faaliyetleri yürütmektedir. Ekibimiz, alanında uzman akademisyenlerden ve profesyonellerden oluşmaktadır.
                  </p>
                  <p>
                    Amacımız, en son teknolojileri kullanarak müşterilerimize özel yazılım çözümleri sunmak ve iş süreçlerini dijitalleştirerek rekabet avantajı sağlamaktır. Geliştirdiğimiz çözümlerle işletmenizin tüm gereksinimlerini karşılamayı hedefliyoruz.
                  </p>
                  <ul>
                    <li>
                      <i className="ion-android-checkmark-circle" /> iOS, Android ve Windows platformlarına yönelik uygulama geliştirme hizmetleri.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle" /> Kurumsal web tasarım ve yazılım çözümleri.
                    </li>
                    <li>
                      <i className="ion-android-checkmark-circle" /> Danışmanlık ve eğitim hizmetleri ile şirketinizin yazılım ihtiyaçlarını karşılıyoruz.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* #about */}
        {/*==========================
  Services Section
      ============================*/}
        <section id="services" className="section-bg">
          <div className="container">
            <header className="section-header">
              <h3>Hizmetlerimiz</h3>
              <p>
                Softsense olarak, iş süreçlerinizi iyileştirmek ve teknolojik
                ihtiyaçlarınızı karşılamak için sunduğumuz hizmetler.
              </p>
            </header>
            <div className="row">
              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#fceef3" }}>
                    <FaMobileAlt style={{ color: "#ff689b" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Uygulama Geliştirme</a>
                  </h4>
                  <p className="description">
                    iOS, Android, Windows. Tüm platformlarda sorunsuz çalışan
                    uygulamalar geliştirmenize yardımcı olmaktayız.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#fff0da" }}>
                    <FaPaintBrush style={{ color: "#e98e06" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Web Tasarım</a>
                  </h4>
                  <p className="description">
                    Kurumsal kimliğinize uygun, güvenilir ve yaratıcı web
                    tasarımlarıyla hizmetinizdeyiz.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#e6fdfc" }}>
                    <FaCode style={{ color: "#3fcdc7" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Web Yazılım</a>
                  </h4>
                  <p className="description">
                    İhtiyaçlarınıza uygun, kullanıcı dostu ve güvenilir web
                    yazılımları geliştiriyoruz.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#eafde7" }}>
                    <FaChalkboardTeacher style={{ color: "#41cf2e" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Kurumsal Eğitim</a>
                  </h4>
                  <p className="description">
                    Yazılım sektöründe ekibinize yönelik kapsamlı eğitimlerle
                    dijital dönüşümünüzü hızlandırıyoruz.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#e1eeff" }}>
                    <FaComments style={{ color: "#2282ff" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Danışmanlık Hizmetleri</a>
                  </h4>
                  <p className="description">
                    Yazılım projelerinizin her aşamasında deneyimli ekibimizle
                    danışmanlık hizmeti sunuyoruz.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.4s"
              >
                <div className="box">
                  <div className="icon" style={{ background: "#ecebff" }}>
                    <FaTools style={{ color: "#8660fe" }} />
                  </div>
                  <h4 className="title">
                    <a href="#">Yazılım Geliştirme Çözümleri</a>
                  </h4>
                  <p className="description">
                    Ürünlerinizi baştan sona geliştirebilir veya sürecin zorlu
                    kısımlarını sizin için üstlenebiliriz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* #services */}
        {/*==========================
  Why Us Section
      ============================*/}
        <section id="why-us" className="wow fadeIn">
          <div className="container-fluid">
            <header className="section-header">
              <h3>Neden Bizi Seçmelisiniz?</h3>
              <p>
                Softsense olarak, müşterilerimize en yenilikçi ve etkili çözümleri sunmak için buradayız. Bizi tercih eden müşterilerimizin projelerinde fark yaratıyoruz.
              </p>
            </header>
            <div className="row">
              <div className="col-lg-6">
                <div className="why-us-img">
                  <img src="theme/assets/img/why-us.jpg" alt="Neden Biz?" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="why-us-content">
                  <p>
                    Müşterilerimizin ihtiyaçlarına uygun, esnek ve ölçeklenebilir yazılım çözümleri sunuyoruz. Sektördeki bilgi birikimimiz ve yenilikçi yaklaşımımızla iş süreçlerinizi bir üst seviyeye taşıyoruz.
                  </p>
                  <p>
                    Ekibimiz, farklı disiplinlerden gelen profesyonellerden oluşmaktadır. Projelerinize katma değer sağlayan özel yazılım çözümleri üretiyoruz. Her projeyi titizlikle planlar ve hedeflerinizi en iyi şekilde gerçekleştirmek için çalışırız.
                  </p>
                  <div className="features wow bounceInUp clearfix">
                    <i className="fa fa-diamond" style={{ color: "#f058dc" }} />
                    <h4>Yenilikçi Çözümler</h4>
                    <p>
                      Sektördeki en güncel teknolojileri ve yenilikçi çözümleri kullanarak, iş süreçlerinizi dijitalleştiriyoruz. Yaratıcı ve etkin çözümlerimizle projelerinizi başarıya ulaştırıyoruz.
                    </p>
                  </div>
                  <div className="features wow bounceInUp clearfix">
                    <i
                      className="fa fa-object-group"
                      style={{ color: "#ffb774" }}
                    />
                    <h4>Kapsamlı Deneyim</h4>
                    <p>
                      Alanında uzman ekibimiz, büyük ölçekli projelerde kazandığı deneyimle, her türlü yazılım ihtiyacınıza çözümler sunuyor. Deneyimimizle, projelerinizde en iyi sonuçları elde etmenizi sağlıyoruz.
                    </p>
                  </div>
                  <div className="features wow bounceInUp clearfix">
                    <i className="fa fa-language" style={{ color: "#589af1" }} />
                    <h4>Yüksek Kalite Standartları</h4>
                    <p>
                      Her projeyi, en yüksek kalite standartlarında sunarız. Detaylara verdiğimiz önem ve müşteri memnuniyetine odaklanmamızla, uzun vadeli başarıyı hedefleriz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row counters">
              <div className="col-lg-3 col-6 text-center">
                <span data-toggle="counter-up">500+</span>
                <p>Mutlu Müşteri</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span data-toggle="counter-up">700+</span>
                <p>Tamamlanan Proje</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span data-toggle="counter-up">2,000+</span>
                <p>Destek Saati</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span data-toggle="counter-up">8</span>
                <p>Bilgisayar Mühendisi</p>
              </div>
            </div>
          </div>
        </section>
        <section id="call-to-action" className="wow fadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-left">
                <h3 className="cta-title">Hemen Başlayın!</h3>
                <p className="cta-text">
                  İşinizi bir adım öteye taşımaya hazır mısınız? Softsense olarak, yenilikçi çözümlerimizle size özel projeleri hayata geçirmeye hazırız. Dijital dönüşüm yolculuğunuzda size rehberlik edecek uzman ekibimiz, iş süreçlerinizi daha verimli hale getirecek yazılım çözümleri sunmak için burada. Zaman kaybetmeden projelerinizde fark yaratın!
                </p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a className="cta-btn align-middle" href="#contact">
                  Şimdi İletişime Geçin
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="features">
          <div className="container">
            <div className="row feature-item">
              <div className="col-lg-6 wow fadeInUp">
                <img src="theme/assets/img/features-1.svg" className="img-fluid" alt="Yenilikçi Çözümler" />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                <h4>
                  İşinizi Geleceğe Taşıyan Yenilikçi Çözümler
                </h4>
                <p>
                  Softsense olarak, işletmenizi bir adım öteye taşıyacak yenilikçi çözümler sunuyoruz. Dijital dönüşümde size liderlik edecek, işletmenizin verimliliğini ve etkinliğini artıracak yazılım çözümleri geliştiriyoruz.
                </p>
                <p>
                  İhtiyacınıza özel yazılımlar sayesinde iş süreçlerinizi optimize ederken, aynı zamanda esneklik ve ölçeklenebilirlik sunuyoruz. Siz işinize odaklanırken biz teknolojiyi sizin için yönetiyoruz.
                </p>
              </div>
            </div>
            <div className="row feature-item mt-5 pt-5">
              <div className="col-lg-6 wow fadeInUp order-1 order-lg-2">
                <img src="theme/assets/img/features-2.svg" className="img-fluid" alt="Güçlü Teknoloji Altyapısı" />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                <h4>
                  Güçlü Teknoloji Altyapısıyla Geleceğe Hazır Olun
                </h4>
                <p>
                  Kullandığımız güçlü teknoloji altyapısı ile işletmenizin geleceğe uyum sağlamasını kolaylaştırıyoruz. İster bulut tabanlı çözümler, ister entegre yazılımlar olsun, her adımda yanınızdayız.
                </p>
                <p>
                  Müşteri memnuniyetini en üst seviyeye çıkarmak için sürekli gelişen teknolojilere entegre çözümler sunuyoruz. Verilerinizi güvenle işleyebileceğiniz, esnek ve yüksek performanslı sistemlerle işletmenizi geleceğe taşıyın.
                </p>
                <p>
                  Softsense olarak size, güvenli, hızlı ve verimli sistemler sunarak, dijital dünyadaki tüm fırsatlardan en iyi şekilde yararlanmanızı sağlıyoruz.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Porfolio */}
        <section id="portfolio" className="section-bg custom-portfolio">
          <div className="container">
            <header className="section-header custom-header">
              <h3 className="section-title">Portföyümüz</h3>
            </header>
            <div className="portfolio-items-container">
              <div className="portfolio-item custom-portfolio-item">
                <div className="custom-portfolio-image">
                  <img
                    src="theme/assets/img/portfolio/softbor.png"
                    alt="SoftBor"
                  />
                </div>
                <div className="custom-portfolio-details">
                  <h4>SoftBor</h4>
                  <p>
                    Softsense, bor katkılı ve yerli hammaddelerle geliştirilen yanmaz ve
                    ısıyı geçirmeyen izolasyon malzemesiyle inşaat, otomotiv ve havacılık
                    sektörlerinde devrim yaratmayı hedeflemektedir. Ürün, 1000°C sıcaklıkta
                    bile deformasyona uğramaz ve piyasadaki alternatiflerine göre %60 daha
                    ekonomiktir. Yanmaz yapısı sayesinde yangın riskini minimuma indirirken,
                    ses yalıtımı konusunda da yüksek performans sunar. Softsense, yenilikçi
                    çözümleriyle sektöre öncülük etmektedir.
                  </p>
                  <a href="/projects" className="custom-btn">
                    Detaylı Bilgi
                  </a>
                </div>
              </div>
              <div className="portfolio-item custom-portfolio-item">
                <div className="custom-portfolio-image">
                  <img
                    src="theme/assets/img/portfolio/ar.png"
                    alt="Artırılmış Gerçeklik"
                  />
                </div>
                <div className="custom-portfolio-details">
                  <h4>Artırılmış Gerçeklik</h4>
                  <p>
                    Softsense, artırılmış gerçeklik teknolojisi kullanarak elektrik panolarındaki
                    ekipmanların uzaktan yönetimini geliştiriyor. Özellikle MiCOM P111 rölesi gibi
                    gelişmiş koruma rölelerinin yönetiminde hata oranlarını minimuma indiriyor ve bakım
                    süreçlerini hızlandırıyoruz. Artırılmış gerçeklik sayesinde operatörler, sahada
                    bulunmadan anlık verilere erişerek ekipmanların durumunu değerlendirebiliyor ve
                    müdahalelerde bulunabiliyor. Bu yaklaşım, hem operasyonel verimliliği artırıyor hem
                    de güvenliği üst seviyeye taşıyor. Softsense, yenilikçi çözümleriyle elektrik,
                    otomotiv ve enerji sektörlerinde fark yaratmayı amaçlamaktadır.
                  </p>
                  <a href="/projects" className="custom-btn">
                    Detaylı Bilgi
                  </a>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section id="faq">
          <div className="container">
            <header className="section-header">
              <h3>Sıkça Sorulan Sorular</h3>
              <p>
                Softsense olarak sunduğumuz hizmetlerle ilgili en çok merak edilen sorular ve yanıtları.
              </p>
            </header>
            <ul id="faq-list" className="wow fadeInUp">
              <li>
                <a data-toggle="collapse" className="collapsed" href="#faq1">
                  Yazılım geliştirme süreciniz nasıl işliyor?{" "}
                  <i className="ion-android-remove" />
                </a>
                <div id="faq1" className="collapse" data-parent="#faq-list">
                  <p>
                    Yazılım geliştirme sürecimiz, müşterilerimizin ihtiyaçlarını analiz etmekle başlar. Bu analiz sonucunda tasarım ve geliştirme aşamalarına geçeriz. Proje tamamlandığında detaylı testler yaparak kullanıma hazır hale getiririz. Tüm süreç boyunca müşteriyle sürekli iletişimde kalır ve geri bildirimleri dikkate alırız.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq2" className="collapsed">
                  Hangi platformlar için yazılım geliştiriyorsunuz? <i className="ion-android-remove" />
                </a>
                <div id="faq2" className="collapse" data-parent="#faq-list">
                  <p>
                    Softsense olarak, iOS, Android ve Windows platformları için mobil ve masaüstü yazılım çözümleri sunuyoruz. Ayrıca, web tabanlı uygulamalar ve bulut entegrasyonu gibi platformlar arası çözümler de geliştirmekteyiz.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq3" className="collapsed">
                  Yazılım geliştirme süresi ne kadar sürer? <i className="ion-android-remove" />
                </a>
                <div id="faq3" className="collapse" data-parent="#faq-list">
                  <p>
                    Projenin kapsamına ve gereksinimlerine bağlı olarak yazılım geliştirme süresi değişiklik gösterebilir. Küçük projeler birkaç hafta sürebilirken, büyük ve kompleks projeler birkaç ayı bulabilir. Süreci hızlandırmak için müşteriyle yakın iş birliği içinde çalışırız.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq4" className="collapsed">
                  Destek ve bakım hizmetleri sunuyor musunuz? <i className="ion-android-remove" />
                </a>
                <div id="faq4" className="collapse" data-parent="#faq-list">
                  <p>
                    Evet, geliştirdiğimiz tüm yazılımlar için destek ve bakım hizmetleri sunuyoruz. Yazılımınızın güncel kalması ve performansının sürdürülebilir olması için düzenli bakım yapıyor, gerektiğinde sorunları çözmek için hızlıca müdahale ediyoruz.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq5" className="collapsed">
                  Projem için özel yazılım çözümleri geliştirebilir misiniz? <i className="ion-android-remove" />
                </a>
                <div id="faq5" className="collapse" data-parent="#faq-list">
                  <p>
                    Elbette! Softsense olarak, müşterilerimizin spesifik ihtiyaçlarına yönelik özelleştirilmiş yazılım çözümleri sunuyoruz. Her projeyi müşterinin taleplerine göre şekillendiriyor ve en uygun teknolojileri kullanarak hayata geçiriyoruz.
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq6" className="collapsed">
                  Yazılım geliştirme maliyetleriniz nedir? <i className="ion-android-remove" />
                </a>
                <div id="faq6" className="collapse" data-parent="#faq-list">
                  <p>
                    Yazılım geliştirme maliyetleri, projenin karmaşıklığına ve gereksinimlerine göre değişiklik göstermektedir. Projenin kapsamını belirledikten sonra, size özel bir teklif sunuyoruz. Amacımız, kaliteli ve uygun maliyetli çözümler sunarak, işletmenizin verimliliğini artırmak.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <div className="custom-form" id="contact">
          <h4>Bizimle İletişime Geçin</h4>
          <p>
            Yazılım çözümlerimiz hakkında bilgi almak veya projeleriniz için destek talep etmek
            isterseniz, size yardımcı olmaktan memnuniyet duyarız.
          </p>
          <form className="contactForm">
            <div className="custom-form-group">
              <input
                type="text"
                name="name"
                className="custom-form-control"
                id="name"
                placeholder="Adınız ve Soyadınız"
                data-rule="minlen:4"
                data-msg="Lütfen en az 4 karakter giriniz."
              />
              <div className="custom-validation"></div>
            </div>
            <div className="custom-form-group">
              <input
                type="email"
                className="custom-form-control"
                name="email"
                id="email"
                placeholder="E-posta Adresiniz"
                data-rule="email"
                data-msg="Lütfen geçerli bir e-posta adresi giriniz."
              />
              <div className="custom-validation"></div>
            </div>
            <div className="custom-form-group">
              <input
                type="text"
                className="custom-form-control"
                name="subject"
                id="subject"
                placeholder="Konu"
                data-rule="minlen:8"
                data-msg="Lütfen en az 8 karakter içeren bir konu giriniz."
              />
              <div className="custom-validation"></div>
            </div>
            <div className="custom-form-group">
              <textarea
                className="custom-form-control"
                name="message"
                rows="5"
                data-rule="required"
                data-msg="Lütfen bize bir mesaj yazınız."
                placeholder="Mesajınız"
              ></textarea>
              <div className="custom-validation"></div>
            </div>
            <div id="sendmessage">Mesajınız başarıyla gönderildi. Teşekkür ederiz!</div>
            <div id="errormessage"></div>
            <div className="custom-text-center">
              <a href="#" title="Gönder" className="custom-button">
                Gönder
              </a>
            </div>
          </form>
        </div>

      </main>
    </>
  )
}
